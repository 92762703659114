/**
 * 下層ページのシェア
 */
import encoding from 'encoding-japanese';


export default class SubShare{

    constructor(){
        this._init();
    }


    _init(){
        const shareurl = {
            twitter: 'https://twitter.com/intent/tweet?text=',
            line: 'https://line.me/R/msg/text/?'
        };

       [...document.querySelectorAll('.js-page-share')].forEach($share => {
            const txt = encoding.urlEncode($share.getAttribute('data-share'));
            const link = $share.classList.contains('-twitter') ? shareurl.twitter+txt: shareurl.line+txt;
            $share.setAttribute('href', link);
       })
    }
}