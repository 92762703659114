import SubShare from './_share';
import ScrollAnime from './_scroll';


export default class Main {

    constructor(){
        this._init();
    }


    _init(){
        const share = new SubShare();
        const scroll = new ScrollAnime();
        
        this._setIntro();
        this._setSmoothScroll();
    }
    

    /**
     * ページ表示の際のアニメ
     */
    _setIntro(){
        const $lower = document.querySelector('.l-lower');
        if($lower) $lower.classList.add('-ready');
    }


    /**
     * アンカーリンクのセット
     */
    _setSmoothScroll(){
        const links = [...document.querySelectorAll('a.js-smooth')];
        
        links.forEach( $a => {
            
            $a.addEventListener('click', (e)=>{
                e.preventDefault();
                const $target = document.querySelector($a.getAttribute('href'));
                if(!$target) return false;
                
                const adjust = parseInt($target.getAttribute('data-adjust'));
                const targetAdjust = adjust ? adjust : 0;
                const targetPos = parseInt(this._getAbsoluteTop($target)) - targetAdjust;
                gsap.to([document.querySelector("html"),document.querySelector("body")], {duration:0.5, scrollTop:targetPos, ease:'power2.out'});
            });
        });
    }

    _getAbsoluteTop(_selector){
        return _selector.getBoundingClientRect().top + window.pageYOffset;
    }
}
