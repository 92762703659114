import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';


export default class ScrollAnime{


    constructor(){
        this._init();
    }


    _init(){
        gsap.registerPlugin(ScrollTrigger);

        // 下からのフェードアップで表示する
        this._fadeUp();

        // クラスを付加する
        this._setToggleClassOnce();
    }


    _fadeUp(){
        
        [...document.querySelectorAll('.js-scroll-fadeup')].forEach((target)=>{
            const _delay = target.getAttribute('data-delay') ? target.getAttribute('data-delay') : 0;
            const _start = target.getAttribute('data-start') ? target.getAttribute('data-start') : 'top 70%';

            gsap.fromTo(
                target,
                { y: 20, opacity:0 },
                {
                    duration: 0.5, 
                    delay: _delay,
                    y: 0,
                    opacity: 1,
                    scrollTrigger: {
                        ease: 'power2.out',
                        start: _start,
                        trigger: target,
                        toggleClass: {targets: target, className: "-viewed"},
                        once: true
                    },
                }
            );
        });
    }
    
    _setToggleClassOnce(){

        [...document.querySelectorAll('.js-scroll-class')].forEach((target)=>{
            const _start = target.getAttribute('data-start') ? target.getAttribute('data-start') : 'top 70%';
            const _end = target.getAttribute('data-end') ? target.getAttribute('data-end') : 'bottom top';

            ScrollTrigger.create({
                trigger: target,
                start: _start,
                end: _end, 
                toggleClass: {targets: target, className: '-viewed'},
                once: true
            });
        });
    }
}
